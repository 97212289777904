import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { StreamApi } from '@app/features/app-creation-and-configuration/models/app-config.model';
import { RadioTabModel } from '@app/features/app-creation-and-configuration/models/app-tabs.model';
import {
  AddConfigAsset,
  DeleteStream,
  FetchMountPointsRequest,
  RemoveImage,
  UpdateAppConfig,
} from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import {
  AppConfigurationState,
  UserRadio,
} from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { DropdownOption } from '@app/ui/components/dropdown/dropdown.model';
import { Selectedimage } from '@app/ui/components/image-upload/image-upload.component';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import {
  Observable,
  Subject,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  filter,
} from 'rxjs';

@Component({
  selector: 'rk-stream-editor',
  templateUrl: './stream-editor.component.html',
  styleUrl: './stream-editor.component.scss',
})
export class StreamEditorComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.currentAppConfigStreamList)
  currentAppConfigStreamList$: Observable<StreamApi[]>;

  @Select(AppConfigurationState.currentAppConfigPlayerTab)
  currentAppConfigPlayerTab$: Observable<RadioTabModel>;

  @Select(AppConfigurationState.userRadios)
  userRadios$: Observable<UserRadio[]>;

  @Select(AppConfigurationState.icecastMountPoints)
  icecastMountPoints$: Observable<string[]>;

  @Input()
  streamId = 0;

  private readonly subscriptions = new Subscription();
  private readonly streamUrlChanged = new Subject<string>();
  private readonly titleUrlChanged = new Subject<string>();
  private readonly streamNameChanged = new Subject<string>();

  constructor(
    private readonly store: Store,
    private readonly translate: TranslateService,
  ) {}

  logoUrl: string;
  stream: StreamApi;
  currentStreamType: DropdownOption;
  streamURLControl: FormControl;
  titleURLControl: FormControl;
  currentStreamName: string;
  currentIcecastMountPoint: DropdownOption;
  currentUserRadio: DropdownOption;
  userRadios: any = [];
  userRadioDropdownOptions: DropdownOption[] = [];
  icecastMountpoints: DropdownOption[] = [];
  streamTypeOptions: DropdownOption[] = [
    {
      value: 'radioking',
      label: 'RadioKing',
      disabled: false,
    },
    {
      value: 'shoutcast',
      label: 'Shoutcast v1',
      disabled: false,
    },
    {
      value: 'icecast',
      label: 'Icecast',
      disabled: false,
    },
    {
      value: 'autre',
      label: this.translate.instant('stream-edit.other'),
      disabled: false,
    },
  ];

  ngOnInit(): void {
    this.streamURLControl = new FormControl('', [Validators.required, this.urlValidator]);
    this.titleURLControl = new FormControl('', [this.urlValidator]);

    // set existing stream settings
    this.subscriptions.add(
      this.currentAppConfigStreamList$
        .pipe(filter(streams => !!streams))
        .subscribe(streams => {
          this.stream = streams[this.streamId];
          this.currentStreamType = this.streamTypeOptions.find(
            option => option?.value === this.stream?.typeFlux,
          );
          this.streamURLControl.setValue(this.stream.urlFlux);
          this.titleURLControl.setValue(this.stream.urlTitrage);
          // checks for stream name
          if (this.stream.nomFlux) {
            this.currentStreamName = this.stream.nomFlux;
          } else {
            // adds new radio name if necessary
            this.currentStreamName = this.translate.instant('new-stream.name');
            this.store.dispatch(
              new UpdateAppConfig(
                ['streams', this.streamId.toString(), 'nomFlux'],
                this.currentStreamName,
              ),
            );
          }
          // fetches mount points if necessary
          if (this.stream.typeFlux === 'icecast') {
            this.store.dispatch(new FetchMountPointsRequest(this.streamURLControl.value));
          }
        }),
    );

    // set user radios
    this.subscriptions.add(
      this.userRadios$.pipe(filter(radios => !!radios)).subscribe(radios => {
        this.userRadios = [];
        this.userRadioDropdownOptions = [];
        if (radios.length > 0) {
          radios.map(radio => this.userRadios.push(radio));
          this.userRadios.map((radio: UserRadio) =>
            this.userRadioDropdownOptions.push({
              value: radio.idradio.toString(),
              label: radio.name,
              disabled: false,
            }),
          );
        }
        if (this.stream?.idradio) {
          this.currentUserRadio = this.userRadioDropdownOptions.find(
            option => option?.value === this.stream?.idradio.toString(),
          );
          if(!this.currentUserRadio){
            this.currentUserRadio = this.userRadioDropdownOptions[0];
            this.store.dispatch(
              new UpdateAppConfig(
                ['streams', this.streamId.toString(), 'idradio'],
                this.userRadioDropdownOptions[0].value,
              ),
            );
          }
        } else {
          this.currentUserRadio = this.userRadioDropdownOptions[0];
          this.store.dispatch(
            new UpdateAppConfig(
              ['streams', this.streamId.toString(), 'idradio'],
              this.userRadioDropdownOptions[0].value,
            ),
          );
        }
      }),
    );

    // set stream logo
    this.currentAppConfigStreamList$
      .pipe(filter(streams => !!streams))
      .subscribe(streams => {
        if (streams[this.streamId].logofluxappfield) {
          const substring = 'data:image';
          if (streams[this.streamId].logofluxappfield.includes(substring)) {
            this.logoUrl = streams[this.streamId].logofluxappfield;
          } else {
            this.logoUrl = `https://www.${
              environment.urls.RADIOKING_DOMAIN
            }/upload/applications/${streams[this.streamId].logofluxappfield}`;
          }
        } else {
          this.logoUrl = null;
        }
      });

    // stream URL changes
    this.subscriptions.add(
      this.streamURLControl.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(newUrlValue => {
          this.store.dispatch(
            new UpdateAppConfig(
              ['streams', this.streamId.toString(), 'urlFlux'],
              newUrlValue,
            ),
          );
          this.store.dispatch(
            new UpdateAppConfig(
              ['streams', this.streamId.toString(), 'idflux'],
              this.streamId,
            ),
          );
          if (this.currentStreamType.value === 'icecast') {
            this.store.dispatch(new FetchMountPointsRequest(this.streamURLControl.value));
          }
        }),
    );

    // title URL changes
    this.subscriptions.add(
      this.titleURLControl.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(newUrlValue => {
          this.store.dispatch(
            new UpdateAppConfig(
              ['streams', this.streamId.toString(), 'urlTitrage'],
              newUrlValue,
            ),
          );
        }),
    );

    // stream name changes
    this.subscriptions.add(
      this.streamNameChanged.pipe(debounceTime(500)).subscribe(newNameValue => {
        this.store.dispatch(
          new UpdateAppConfig(
            ['streams', this.streamId.toString(), 'nomFlux'],
            newNameValue,
          ),
        );
      }),
    );

    // set mount points
    this.subscriptions.add(
      this.icecastMountPoints$
        .pipe(filter(mountPoints => !!mountPoints))
        .subscribe(mountPoints => {
          if (mountPoints.length > 0) {
            this.icecastMountpoints = [];
            mountPoints.map(mountPoint =>
              this.icecastMountpoints.push({
                value: mountPoint,
                label: mountPoint,
                disabled: false,
              }),
            );
          }
          if (this.stream?.mountPoint) {
            this.currentIcecastMountPoint = this.icecastMountpoints.find(
              option => option?.value === this.stream?.mountPoint,
            );
          } else {
            this.currentIcecastMountPoint = this.icecastMountpoints[0];
            this.store.dispatch(
              new UpdateAppConfig(
                ['streams', this.streamId.toString(), 'mountPoint'],
                this.icecastMountpoints[0].value,
              ),
            );
          }
        }),
    );
  }

  onStreamTypeChange(streamType: string) {
    this.store.dispatch(
      new UpdateAppConfig(['streams', this.streamId.toString(), 'typeFlux'], streamType),
    );
    if (streamType === 'autre') {
      this.store.dispatch(
        new UpdateAppConfig(['streams', this.streamId.toString(), 'typeTitrage'], 'web'),
      );
    } else {
      this.store.dispatch(
        new UpdateAppConfig(
          ['streams', this.streamId.toString(), 'typeTitrage'],
          streamType,
        ),
      );
    }
  }

  onUserRadioChange(radioId: string) {
    const radioSlug = this.userRadios.find(
      (radio: UserRadio) => radio?.idradio === Number(radioId),
    ).slug;
    const radioKingStreamUrl = `https://${environment.urls.RADIOKING_DOMAIN}/play/${radioSlug}`;
    this.store.dispatch(
      new UpdateAppConfig(['streams', this.streamId.toString(), 'idflux'], this.streamId),
    );
    this.store.dispatch(
      new UpdateAppConfig(
        ['streams', this.streamId.toString(), 'idradio'],
        Number(radioId),
      ),
    );
    this.store.dispatch(
      new UpdateAppConfig(
        ['streams', this.streamId.toString(), 'urlFlux'],
        radioKingStreamUrl,
      ),
    );
  }

  onMounPointChange(mountPoint: string) {
    this.store.dispatch(
      new UpdateAppConfig(
        ['streams', this.streamId.toString(), 'mountPoint'],
        mountPoint,
      ),
    );
  }

  onStreamUrlChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.streamUrlChanged.next(target.value);
  }

  onTitleUrlChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.titleUrlChanged.next(target.value);
  }

  onStreamNameChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.streamNameChanged.next(target.value);
  }

  onImageSelected(event: Selectedimage) {
    const image = event.file;
    const displayImageUrl = event.imageUrl;
    this.store.dispatch(
      new AddConfigAsset(
        `logoflux${this.streamId}appfield`,
        image,
        displayImageUrl.toString(),
      ),
    );
    this.store.dispatch(
      new UpdateAppConfig(
        ['streams', this.streamId.toString(), 'logofluxappfield'],
        displayImageUrl.toString(),
      ),
    );
  }

  onRemoveImage() {
    this.store.dispatch(new RemoveImage(`logoflux${this.streamId}appfield`));
    this.store.dispatch(
      new UpdateAppConfig(
        ['streams', this.streamId.toString(), 'logofluxappfield'],
        null,
      ),
    );
  }

  deleteStream() {
    this.store.dispatch(new DeleteStream(this.streamId));
  }

  urlValidator(control: AbstractControl): { [key: string]: any } | null {
    if (control.value === '') {
      return null;
    }

    let url;
    try {
      url = new URL(control.value);
    } catch (_) {
      return { invalidUrl: true };
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
      ? null
      : { invalidUrl: true };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
